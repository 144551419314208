.custom-button {
    padding-left: 0;
    gap: 3px;
  }
  
  @media (max-width: 900px) {
    .hide-on-small {
      display: none;
    }
  
    .custom-button {
      padding: 0;
    }
  }
  